import type {NextComponentType} from 'next';
import Link from 'next/link';
import {useEffect, useState} from 'react';
import {strapi_end_point} from '../config';
import {Router, useRouter} from 'next/router';
import Image from 'next/image';
interface IDisplayStyle {
	display: string;
}

const CourseDropdown: NextComponentType = () => {
	const [style, setStyle] = useState<IDisplayStyle>({display: 'none'});
	const [hovered, setHovered] = useState<boolean>(false);
	const toggleHover = () => setHovered(!hovered);

	const [catData, setCatData] = useState([]);
	const [isLoading, setLoading] = useState<boolean>(true);
	const hideShow = (index: number, display: string) => {
		(document.getElementsByClassName('childd' + index) as HTMLCollectionOf<HTMLElement>)[0].style.display = display;
	};
	const activeParent = (index: number, color: string) => {
		(document.getElementsByClassName('parentt' + index) as HTMLCollectionOf<HTMLElement>)[0].style.background = color;
		(document.getElementsByClassName('parentt' + index) as HTMLCollectionOf<HTMLElement>)[0].style.borderRadius = '5px';
	};

	const router = useRouter();
	const openCoursePage = (page_name: string) => {
		window.open('https://course.gurucool.xyz/' + page_name);
	};
    const myLoader = ({src}:{src:any}) => {
		return `${src}`;
	};
	useEffect(() => {
		fetch(`${strapi_end_point}/api/popular-course-categories?populate=*`)
			.then((res) => res.json())
			.then((dataOne) => {
				fetch(`${strapi_end_point}/api/popular-courses?populate=*&pagination[pageSize]=500`)
					.then((res) => res.json())
					.then((dataTwo) => {
						setLoading(false);
						Array.from(dataOne.data).map((valueOne: any) => {
							valueOne.attributes.course_id.data.map((valueTwo: any) => {
								Array.from(dataTwo.data).map((valueThree: any, indexThree: number) => {
									if (valueTwo.id === valueThree.id) {
										valueTwo.course_id = valueThree.attributes.course_id;
									}
								});
							});
						});
						setCatData(Array.from(dataOne.data));
					});
				setLoading(false);
			});
	}, []);

	return (
		<>
			<div className=''>
				<a
					className='block lg:inline-block lg:mt-0 text-white lg:px-4 py-4 hover:text-primary cursor-pointer'
					onClick={(e) => {
						e.preventDefault();
					}}
					onMouseEnter={(e) => {
						setStyle({display: 'block'});
						hideShow(0, 'block');
						activeParent(0, 'rgb(249, 201, 51)');
					}}
					onMouseLeave={(e) => {
						setStyle({display: 'none'});
					}}>
					<span className='block text-sm uppercase text-theme'>Popular</span>
					<span className='font-medium'>
						Courses <i className='fas fa-chevron-down'></i>
					</span>
				</a>
				<div
					className='large-dropdown bg-white lg:absolute fixed lg:top-auto top-0 z-[999] left-0 right-0 w-full lg:p-4 shadow-lg lg:h-auto h-screen'
					style={style}
					onMouseEnter={(e) => {
						setStyle({display: 'block'});
					}}
					onMouseOver={(e) => {
						hideShow(0, 'block');
						if (document.getElementsByClassName('childd' + 0).length !== 0 && (document.getElementsByClassName('childd' + 0) as HTMLCollectionOf<HTMLElement>)[0].style.display === 'block') {
							for (let i = 1; i < catData.length; i++) {
								if (document.getElementsByClassName('childd' + i).length !== 0 && (document.getElementsByClassName('childd' + i) as HTMLCollectionOf<HTMLElement>)[0].style.display === 'block') {
									hideShow(0, 'none');
								}
							}
						}
						activeParent(0, 'rgb(249, 201, 51)');
					}}
					onMouseLeave={(e) => {
						setStyle({display: 'none'});
					}}>
					<div className='flex flex-wrap lg:p-0 p-4'>
						<div className='lg:w-1/3 lg:static fixed z-20 top-0 left-0 right-0 lg:overflow-visible overflow-y-scroll'>
							<div className='bg-white shadow lg:hidden flex justify-between p-4 pb-3 mb-3'>
								<h4 className='text-black text-[17px] font-normal'>Courses</h4>
								<a
									onClick={(e) => {
										e.preventDefault();
										setStyle({display: 'none'});
									}}>
									<i className='text-lg  fas fa-xmark'></i>
								</a>
							</div>
							<h4 className='text-black text-lg font-medium mb-3 lg:px-0 px-4 hidden lg:block'>Browse by Domains</h4>
							<ul onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
								{!isLoading &&
									catData.map((valueOne: any, indexOne: number) => {
										return (
											<li key={indexOne} className={'mx-3 lg:mx-0 parentt' + indexOne}>
												<a
													onClick={(e) => {
														e.preventDefault();
														activeParent(indexOne, 'rgb(255, 255, 255)');
														console.log(document.getElementsByClassName('childd' + indexOne)[0]);
														(document.getElementsByClassName('childd' + indexOne) as HTMLCollectionOf<HTMLElement>)[0].classList.remove('hidemobileMenu');
													}}
													className='text-black flex justify-between py-3 px-[6px] lg:p-3 items-center hover:bg-primary outline-black rounded-md cursor-pointer'
													onMouseEnter={(e) => {
														hideShow(indexOne, 'block');
													}}
													onMouseMove={(e) => {
														activeParent(0, 'rgb(255, 255, 255)');
													}}
													onMouseLeave={(e) => {
														hideShow(indexOne, 'none');
													}}>
													<span className='dropdown-icon'>
													     <Image loader={myLoader} src={valueOne.attributes.logo.data.attributes.url} alt=''  width={25} height={25} className="align-middle" />
														<span className='ml-2 text-[15px]'> {valueOne.attributes.name}</span>
													</span>
													<i className='fas fa-chevron-right'></i>
												</a>
											</li>
										);
									})}
							</ul>
						</div>
						<div className='lg:w-[66%] lg:static fixed z-99 top-0 left-0 right-0 bg-white z-50 w-full lg:overflow-visible overflow-y-scroll'>
							{catData.map((valueTwo: any, indexTwo: number) => {
								return (
									<div
										key={indexTwo}
										onMouseEnter={(e) => {
											hideShow(indexTwo, 'block');
											activeParent(indexTwo, 'rgb(249, 201, 51)');
										}}
										onMouseMove={(e) => {
											activeParent(0, 'rgb(255, 255, 255)');
										}}
										onMouseLeave={(e) => {
											hideShow(indexTwo, 'none');
											activeParent(indexTwo, 'rgb(255, 255, 255)');
										}}
										style={indexTwo === 0 ? {display: 'block'} : {display: 'none'}}
										className={'childd' + indexTwo + ' ' + 'lg:py-0 lg:px-6 lg:h-full h-screen hidemobileMenu'}>
										<div className='bg-white shadow lg:hidden flex justify-between p-4 pb-3 mb-3'>
											<a
												onClick={(e) => {
													e.preventDefault();
													hideShow(indexTwo, 'none');
												}}>
												<i className='mb-[-1] fas fa-arrow-left'></i>
												<span className='text-[17px] ml-3'>{valueTwo.attributes.name}</span>
											</a>
											<a
												onClick={(e) => {
													e.preventDefault();
													hideShow(indexTwo, 'none');
													setStyle({display: 'none'});
												}}>
												<i className='text-lg  fas fa-xmark'></i>
											</a>
										</div>
										<div className='lg:p-0 p-4'>
											<h4 className='text-black font-medium text-lg mb-3'>Popular Programs</h4>

											<div className='grid sm:grid-cols-2 grid-cols-1 gap-2 md:gap-8'>
												{valueTwo.attributes.course_id.data.map((valueThree: any, indexThree: number) => {
													if(!valueThree.course_id || valueThree.course_id.data.length==0){
														return false;
													}
													return (
														<div
															onClick={(e) => {
																e.preventDefault();
																openCoursePage(valueThree.course_id.data.attributes.slug);
															}}
															key={indexThree}
															className={indexThree > 5 ? ' bg-white cursor-pointer drop-shadow p-3 text-left rounded-md mb-6' : 'cursor-pointer bg-white drop-shadow p-3 rounded-md'}>
															<p className='cursor-pointer text-left mb-3 mt-2 text-[15px]'>{valueThree.course_id.data.attributes.title}</p>
															<span className='text-left text-sm mr-4'>{valueThree.course_id.data.attributes.duration}</span>
															<span className='text-left text-sm'>{valueThree.course_id.data.attributes.offerbyName}</span>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default CourseDropdown;
function getInitialState() {
	throw new Error('Function not implemented.');
}