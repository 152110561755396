import '../styles/globals.scss';
import Script from 'next/script';
import type {AppProps} from 'next/app';
import Navbar from '../components/Navbar';
import Footer from './../components/Footer';
import Head from 'next/head';
import {useEffect} from 'react';
import img1 from '../images/server-error/1.png'
import img2 from '../images/server-error/2.png'
import img3 from '../images/server-error/3.png'
import Image from 'next/image';
import axios from 'axios';
import {strapi_end_point} from '../config';
import {getJwt, getUser} from '../utils/handle-session';
import {hotjar} from 'react-hotjar';
function MyApp({Component, pageProps}: AppProps) {
	const initUser = async () => {
		const user = getUser();
		const jwt = getJwt();
		if (user && jwt) {
			const userdata = JSON.parse(user);
			const checkUserRegister = await axios.get(`${strapi_end_point}/api/user-profiles?filters[user_id]=${userdata.id}`);
			if (checkUserRegister.data.data && checkUserRegister.data.data.length == 0) {
				await axios.post(`${strapi_end_point}/api/user-profiles`, {data: {user_id: userdata.id}});
			}
			const checkBalanceEntry = await axios.get(`${strapi_end_point}/api/user-coins?filters[user]=${userdata.id}`);
			if (checkBalanceEntry.data.data.length == 0) {
				await axios.post(`${strapi_end_point}/api/user-coins`, {data: {user: userdata.id, balance: 500}});
				await axios.post(`${strapi_end_point}/api/notifications`, {
					data: {user: userdata.id, title: `You've been rewarded with 500 cool coins as a welcome bonus!`, descp: `You can use these coins to unlock amazing arts and stickers on the Cool Shop.`, is_read: false, url: 'https://coolshop.gurucool.xyz/'},
				});
				await axios.post(`${strapi_end_point}/api/user-transcations`, {data: {user: userdata.id, coins: 500, credit: true, msg: 'Welcome bonus'}});
			}
		}
		return false;
	};
	useEffect(() => {
		hotjar.initialize(3333585, 6);
		initUser();
	}, []);

	return (
		<>
			<Head>
				<title>Padhaai - Free, Quality &amp; Hyperlocal</title>
			</Head>

			<Navbar />
			<main className="md:mt-[76px] main-container" id='main-appbar'>
				<Component {...pageProps} />
			</main>
			<Footer />
			{/* <div className="p-6 flex justify-center items-center h-screen flex-col">
					<div className="mb-4"><Image src={img1} width={180} height={180} alt=""/></div>
					<div className="mb-4"><Image src={img2} width={288} height={22} alt=""/></div>
					<div><Image src={img3} width={409} height={42} alt=""/></div>
				</div> */}
		</>
	);
}

export default MyApp;
