const qs = require('qs');
import { strapi_end_point } from '../config';

export const getJwt = () => {
  const jwt = getCookie('token');
  return jwt || '';
};

export const getUser = () => {
  const user = getCookie('user');
  return user || '';
};
export const RememberUser = (user: Object) => {
  let date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
  document.cookie = `remembered=${JSON.stringify(user)}; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
}
export const getRememberUser = () => {
  const user = getCookie('remembered');
  return user || false;
};

export const checkLogin = () => {
  const user = getUser();
  const jwt = getJwt();
  if (user && jwt) {
    return true;
  }
  return false;
}
export const destroyRemember = () => {
  let date = new Date();
  document.cookie = `remembered=''; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};

export const storeJwt = (jwt: string) => {
  let date = new Date();
  date.setTime(date.getTime() + (30 * 60 * 1000));
  document.cookie = `token=${jwt}; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};


export const storeBitsQuality = (qualty: string) => {
  let date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
  document.cookie = `quality=${qualty}; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};

export const getQuality = () => {
  const quality = getCookie('quality');
  return quality || '';
};

export const storeUser = (user: object) => {
  let date = new Date();
  date.setTime(date.getTime() + (30 * 60 * 1000));
  document.cookie = `user=${JSON.stringify(user)}; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};

export const destroyUser = () => {
  let date = new Date();
  document.cookie = `token=''; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
  document.cookie = `user=''; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};

type Cookie = {
  [key: string]: string;
};

export const getCookie = (name: string) => {
  let cookie: Cookie = {};
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=');
    cookie[k.trim()] = v;
  });
  return cookie[name];
};

export const initializeRazorpay = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };

    document.body.appendChild(script);
  });
};

export const getUserData = async (id: any) => {
  const query = qs.stringify(
    {
      filters: {
        user_id: id,
      },
      fields: ['id', 'first_name', 'last_name', 'about'],
      populate: {
        avatar: '*',
        user_id: {
          fields: ['id', 'username', 'email', 'onesignalToken'],
        }
      },
    },
    {
      encodeValuesOnly: true, // prettify url
    }
  );
  const UserData = await fetch(`${strapi_end_point}/api/user-profiles?${query}`);
  const UserDecode = await UserData.json();
  return UserDecode.data[0];
}

export const CheckEnroll = async (id: any) => {
  const user = getUser();
  const jwt = getJwt();
  if (user && jwt) {
    //check user already liked video or not
    const userdata = JSON.parse(user);
    const query = qs.stringify(
      {
        filters: {
          course: {
            id: id,
          },
          users_permissions_user: {
            id: userdata.id,
          },
        },
        fields: ['id'],
        publicationState: 'live',
        locale: ['en'],
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );

    const Res = await fetch(`${strapi_end_point}/api/course-enrolls?${query}`);
    const data = await Res.json();
    if (data.data !== null && data.meta.pagination.total > 0) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}
export const HtmlDecode = (input:any) => {
  var e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

export const GetOptimizeImage=(input:any)=>{
   try{
     if(input){
       if(input.formats){
          if(input.formats.large){
            return input.formats.large.url
          }else if(input.formats.medium){
            return input.formats.medium.url
          }else if(input.formats.small){
            return input.formats.small.url
          }else{
            return input.formats.thumbnail.url
          }
       }
       return input.url;
     }
     return null;
   }catch(err:any){
    console.log(err.message)
    return null;
   }
}

export const getMode = () => {
  const mode = getCookie('mode');
  return mode || 'light';
};
export const storeMode = (mode: string) => {
  let date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
  document.cookie = `mode=${mode}; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};