import type { NextComponentType } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState, useRef } from 'react';
import { useToggle } from './../hooks/useToggle';
import CourseDropdown from './CourseDropdown';
import { strapi_end_point } from '../config';
import Logo from '../images/logo.png';
import userProfile from '../images/userimg.png';
import userIcon from '../images/profile-dropdown/profile.svg';
import message from '../images/profile-dropdown/message.svg';
import CampusIcon from '../images/campus-dashboard-icon.svg';
import notification from '../images/profile-dropdown/notification.svg';
import users from '../images/profile-dropdown/users.svg';
import { useRouter } from 'next/router';
import { getJwt } from '../utils/handle-session';
import { storeMode, getUser, destroyUser } from '../utils/handle-session';
import { timeSince } from '../utils/time';
import axios from 'axios';
const qs = require('qs');
interface IDisplayStyle {
	display: string;
}

interface IUser {
	id?: number;
	is_admin?: boolean;
	username?: string;
	email?: string;
}

const Navbar: NextComponentType = () => {
	const [mode, setMode] = useState("light");
	const [openTab, setOpenTab] = useState(1);
	const [notificationCount, setNotificationCount] = useState(0);
	const [notifications, setNotifications] = useState([]);
	const [data, setData] = useState([]);
	const [userData, setUserDetails] = useState<any>();
	const [isLoading, setLoading] = useState(true);
	const [isCampusDahsboard, setCampusDahsboard] = useState(false);
	const [notificationLoading, setNotificationLoading] = useState(true);
	const hideShowChild = (indexOne: number, display: string) => {
		(document.getElementsByClassName('child' + indexOne) as HTMLCollectionOf<HTMLElement>)[0].style.display = display;
	};

	const hideShowSubChild = (indexOne: number, indexTwo: number, display: string) => {
		(document.getElementsByClassName('subchild' + indexOne + indexTwo) as HTMLCollectionOf<HTMLElement>)[0].style.display = display;
	};

	// useEffect(() => {

	// }, []);
	const myLoader = ({ src }: { src: any }) => {
		return `${src}`;
	};
	const [style, setStyle] = useState<IDisplayStyle>({ display: 'none' });
	const [profileStyle, setProfileStyle] = useState<IDisplayStyle>({ display: 'none' });
	const [toggleMenu, settoggleMenu] = useState<IDisplayStyle>({ display: 'none' });
	const displayHidden = { display: 'none' };

	const [showProfileDropdown, setShowProfileDropdown] = useState(false);
	const dropdownProfile = useRef<any>(null);
	const buttonProfile = useRef<any>(null);
	useEffect(() => {
		if (!showProfileDropdown) return;
		function handleClick(event: any) {
			if (!buttonProfile.current.contains(event.target)) {
				if (dropdownProfile.current && !dropdownProfile.current.contains(event.target)) {
					setShowProfileDropdown(false);
				}
			}
		}
		window.addEventListener('click', handleClick);
		return () => window.removeEventListener('click', handleClick);
	}, [showProfileDropdown]);

	const [showProfileMobileDropdown, setShowProfileMobileDropdown] = useState(false);

	const [showMe, setShowMe] = useState(false);
	const dropdown = useRef<any>(null);
	const button = useRef<any>(null);
	useEffect(() => {
		// only add the event listener when the dropdown is opened
		if (!showMe) return;
		function handleClick(event: any) {
			if (!button.current.contains(event.target)) {
				if (dropdown.current && !dropdown.current.contains(event.target)) {
					setShowMe(false);
				}
			}
		}
		window.addEventListener('click', handleClick);
		// clean up
		return () => window.removeEventListener('click', handleClick);
	}, [showMe]);

	const [dropdownShow, setDropdownShow] = useToggle(false);
	const [showMobile, setShowMobile] = useToggle(false);

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [user, setUser] = useState<IUser>({});
	const [balance, setBalance] = useState(0);

	const router = useRouter();
	const handleLoginWindow = () => {
		const windowRef = window.open('https://login.gurucool.xyz/');
		const checkWindCloseTimer = setInterval(() => {
			if (windowRef?.closed) {
				clearInterval(checkWindCloseTimer);
				router.push({
					pathname: '/connect/auth/redirect',
				});
			}
		}, 500);
	};

	const handleLogout = () => {
		destroyUser();
		window.location.href = '/';
	};
	const GetUserData = async (us: any) => {
		var uquery = qs.stringify({
			filters: {
				user_id: {
					id: us.id,
				},
			},
			fields: ['id', 'first_name', 'last_name', 'about'],
			populate: {
				avatar: '*',
			},
		});
		var uresponse = await fetch(`${strapi_end_point}/api/user-profiles?${uquery}`);
		var uDecodeData = await uresponse.json();
		if (uDecodeData.data[0]) {
			setUserDetails(uDecodeData.data[0]);
		}
		setIsLoggedIn(true);

		setLoading(false);
		var uquery = qs.stringify({
			filters: {
				parent_user: {
					id: us.id,
				},
			},
			fields: ['id',"is_verify"],
			populate: {
				campus: {
					fields:["id","admin_enable"]
				},
			},
		});
		var uresponse = await fetch(`${strapi_end_point}/api/eduverse-pro-account-2s?${uquery}`);
		var uDecodeData = await uresponse.json();
		if(uDecodeData.data.length>0){
			if(uDecodeData.data[0].attributes.campus.data!=null && uDecodeData.data[0].attributes.campus.data.attributes.admin_enable){
				setCampusDahsboard(true);
			}
		}
	};
	const GetNotifications = async () => {
		const user = getUser();
		const jwt = getJwt();
		if (user && jwt) {
			const us = JSON.parse(user as string);
			let notificationQry = qs.stringify({
				sort: ['createdAt:desc'],
				filters: {
					user: {
						id: us.id,
					},
				},
				fields: ['id', 'title', 'descp', 'is_read', 'url', 'image_link', 'createdAt'],
			});
			let notificationResponse = await fetch(`${strapi_end_point}/api/notifications?${notificationQry}`);
			let notificationDecode = await notificationResponse.json();
			if (notificationDecode.data && notificationDecode.data.length > 0) {
				let noti = 0;
				notificationDecode.data.map((notification: any) => {
					if (!notification.attributes.is_read) {
						noti++;
						// setTimeout(() => {

						// }, 5000);
					}
				});
				setNotificationCount(noti);
				setNotifications(notificationDecode.data);
			}
		}
		setNotificationLoading(false);
	};
	const MarkasRead = async (id: number) => {
		const data1 = {
			data: {
				is_read: true,
			},
		};
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data1),
		};
		var res = await fetch(`${strapi_end_point}/api/notifications/${id}`, requestOptions);
	};
	const GetBalance = async (id: any) => {
		const checkBalanceEntry = await axios.get(`${strapi_end_point}/api/user-coins?filters[user]=${id}`);
		if (checkBalanceEntry.data.data.length > 0) {
			setBalance(checkBalanceEntry.data.data[0].attributes.balance);
		}
	};
	useEffect(() => {
		if (router.isReady) {
			const user = getUser();
			const jwt = getJwt();
			fetch(`${strapi_end_point}/api/study-material-categories?populate[subcat_id][populate][0]=material_id&[fields][1]=name`)
				.then((res) => res.json())
				.then((data) => {
					setData(Array.from(data.data));
					if (user && jwt) {
						const us = JSON.parse(user as string);
						GetBalance(us.id);
						GetNotifications();
						setUser(us);
						GetUserData(us);
					} else {
						setLoading(false);
					}
				});
		}
	}, [router]);
	useEffect(() => {
		if (router.query.studyMaterial) {
			setShowMe(!showMe);
			setStyle({ display: 'block' });
		}
	}, [router.isReady, router]);
	// const ToggleClass = async () => {
	// 	let element = document.getElementById("app-theme");
	// 	element?.classList.toggle("dark-mode");
	// 	if (mode === "light") {
	// 		setMode("dark");
	// 		storeMode("dark");
	// 	} else {
	// 		setMode("light");
	// 		storeMode("light");
	// 	}
	// };

	return (
		<>
			<header className="bg-black fixed top-0 left-0 right-0  w-full z-[999] headerLoaderWrapper" id='courseMenu'>
				{!isLoading ? (
					<div className="innerDiv mx-auto zeroVerticalPad">
						<nav className="flex items-center justify-between flex-wrap relative">
							<div className="mobileLogo flex items-center flex-shrink-0 text-white pt-[5px] lg:pt-1 lg:mr-6">
								<Link href="/">
									<a onClick={() => setShowMe(false)}>
										<Image src={Logo} alt="" width={130} height={45} />
									</a>
								</Link>
							</div>
							<div className="flex lg:hidden relative">
								{isLoggedIn ? (
									<div className="lg:hidden block sm:mr-4 mr-2">
										<button
											className="flex items-center"
											onClick={(e) => {
												setShowProfileMobileDropdown(!showProfileMobileDropdown);
											}}>
											<Image loader={myLoader} src={userData && userData.attributes.avatar.data != null ? userData.attributes.avatar.data.attributes.url : '/man.svg'} alt="" width={56} height={56} className="rounded-full" />
										</button>
										{showProfileMobileDropdown ? (
											<>
												<ul className="dropdown-menu py-4 absolute bg-white top-full rounded-md shadow-lg w-80 min-h-[455px]">
													<li className="flex justify-between py-3 px-4 border-b border-b-gray-300 mb-4">
														<a
															onClick={(e) => {
																e.preventDefault();
																setOpenTab(1);
															}}
															data-toggle="tab"
															href="#link1"
															role="tablist"
															className={openTab === 1 ? 'flex justify-center items-center text-lg bg-[#FFF6D9] w-9 h-9 rounded-full' : 'flex justify-center items-center text-lg w-9 h-9 rounded-full'}>
															<Image src={userIcon} width={20} height={20} alt="" />
														</a>
														<a
															onClick={(e) => {
																e.preventDefault();
																setOpenTab(2);
															}}
															data-toggle="tab"
															href="#link2"
															role="tablist"
															className={openTab === 2 ? 'flex justify-center items-center text-lg bg-[#FFF6D9] w-9 h-9 rounded-full' : 'flex justify-center items-center text-lg w-9 h-9 rounded-full'}>
															<Image src={message} width={20} height={20} alt="" />
														</a>
														<a
															onClick={(e) => {
																e.preventDefault();
																setOpenTab(3);
																GetNotifications();
															}}
															data-toggle="tab"
															href="#link3"
															role="tablist"
															className={openTab === 3 ? 'flex justify-center items-center text-lg bg-[#FFF6D9] w-9 h-9 rounded-full relative' : 'flex justify-center items-center text-lg w-9 h-9 rounded-full relative'}>
															<Image src={notification} width={20} height={20} alt="" />
															{notificationCount > 0 && <span className="absolute bg-[red] w-[20px] h-[20px] rounded-full right-[-5px] top-[-5px] text-[10px] text-white z-[10] flex justify-center items-center">{notificationCount}</span>}
														</a>
														<a
															onClick={(e) => {
																e.preventDefault();
																setOpenTab(4);
															}}
															data-toggle="tab"
															href="#link4"
															role="tablist"
															className={openTab === 4 ? 'flex justify-center items-center text-lg bg-[#FFF6D9] w-9 h-9 rounded-full' : 'flex justify-center items-center text-lg w-9 h-9 rounded-full'}>
															<Image src={users} width={20} height={20} alt="" />
														</a>
													</li>
													<li className={openTab === 1 ? 'block' : 'hidden'} id="link1">
														<ul>
															<li className="py-3 px-4 border-b border-b-gray-300 mb-4">
																<a className="flex items-center mb-3">
																	<Image loader={myLoader} src={userData && userData.attributes.avatar.data != null ? userData.attributes.avatar.data.attributes.url : '/man.svg'} alt="" width={56} height={56} className="rounded-full" />
																	<span className="ml-3 text-lg font-medium">{userData && userData.attributes.first_name ? `${userData.attributes.first_name} ${userData.attributes.last_name}` : user.username}</span>
																</a>
																<span className="inline-block bg-gray-100 px-4 py-1 rounded-full text-sm">
																	<strong className="text-black">{balance}</strong> Coins
																</span>
																<span className="inline-block bg-gray-100 px-4 py-1 rounded-full text-sm ml-3">
																	<i className="far fa-crown text-theme"></i> <strong className="text-black">0</strong>
																</span>
															</li>
															{/* <li
																className="mb-3"
																onClick={(e) => {
																	setShowProfileMobileDropdown(!showProfileMobileDropdown);
																}}>
																<Link href="/mylearning">
																	<a className="flex px-4 py-2 justify-between items-center">
																		<span>
																			<i className="far fa-book-open"></i> <span className="ml-2">My Learning</span>
																		</span>
																		<i className="fas fa-chevron-right"></i>
																	</a>
																</Link>
															</li> */}
															{user.is_admin && (
																<li
																	className="mb-3"
																	onClick={(e) => {
																		setShowProfileMobileDropdown(!showProfileMobileDropdown);
																	}}>
																	<Link href="https://admin.gurucool.xyz/">
																		<a className="flex px-4 py-2 justify-between items-center">
																			<span>
																				<i className="fa-regular fa-toggle-off"></i> <span className="ml-2">Control Panel</span>
																			</span>
																			<i className="fas fa-chevron-right"></i>
																		</a>
																	</Link>
																</li>
															)}
															<li
																className="mb-3"
																onClick={(e) => {
																	setShowProfileMobileDropdown(!showProfileMobileDropdown);
																}}>
																<Link href="/profile">
																	<a className="flex px-4 py-2 justify-between items-center" onClick={setDropdownShow}>
																		<span>
																			<i className="far fa-user"></i> <span className="ml-2">View Profile</span>
																		</span>
																		<i className="fas fa-chevron-right"></i>
																	</a>
																</Link>
															</li>
															{/* <li className="mb-3">
													<Link href="/achievements">
														<a className="flex px-4 py-2 justify-between items-center" onClick={setDropdownShow}>
															<span>
																<i className="far fa-shield"></i> <span className="ml-2">Achievements</span>
															</span>
															<i className="fas fa-chevron-right"></i>
														</a>
													</Link>
												</li> */}
															<li
																className="mb-3"
																onClick={(e) => {
																	setShowProfileMobileDropdown(!showProfileMobileDropdown);
																}}>
																<Link href="/editprofile">
																	<a className="flex px-4 py-2 justify-between items-center" onClick={setDropdownShow}>
																		<span>
																			<i className="far fa-pen-to-square"></i> <span className="ml-2">Edit Profile</span>
																		</span>
																		<i className="fas fa-chevron-right"></i>
																	</a>
																</Link>
															</li>
															{isCampusDahsboard&&<li className="mb-3">
															   <Link href="http://dashboard.gurucool.xyz/">
																	<a className="flex px-4 py-2 justify-between items-center" onClick={setDropdownShow}>
																		<span className='inline-flex items-center'>
																			<Image src={CampusIcon} alt=''/> <span className="ml-2">Campus Dashboard</span>
																		</span>
																		<i className="fas fa-chevron-right"></i>
																	</a>
																</Link>
															</li>}
															<li className="mb-3">
																<button className="flex w-full px-4 py-2 justify-between items-center" onClick={handleLogout}>
																	<span>
																		<i className="fas fa-arrow-right-from-bracket"></i> <span className="ml-2">Log Out</span>
																	</span>
																	<i className="fas fa-chevron-right"></i>
																</button>
															</li>
														</ul>
													</li>
													<li className={openTab === 2 ? 'block text-center py-6' : 'hidden'} id="link2">
														<i className="fas fa-messages text-4xl mb-4 text-[#c3d1dd]"></i>
														<h4 className="font-semibold mb-2">No messages. Yet</h4>
														<p className="text-xs text-[#323c45] mb-4">You can message anyone by hitting 'Send a Message' on their profile.</p>
														<a className="text-black bg-[#ebf2f7] rounded-full py-2 px-5 inline-block text-xs uppercase">Go to inbox</a>
													</li>
													<li className={openTab === 3 ? 'block text-center py-1 px-2' : 'hidden'} id="link3">
														{notifications.length > 0 ? (
															<div className="text-left">
																<h4 className="text-sm font-[600] mb-4">Notifications</h4>
																{!notificationLoading ? (
																	<ul className="max-h-[300px] overflow-y-scroll overflow-x-hidden">
																		{notifications.map((notification: any, index: number) => {
																			let time = timeSince(new Date(notification.attributes.createdAt));
																			return (
																				<li key={index}>
																					<a
																						onClick={async () => {
																							if (!notification.attributes.is_read) {
																								await MarkasRead(notification.id);
																							}
																							if (notification.attributes.url) {
																								window.location.href = notification.attributes.url;
																							}
																						}}
																						className="flex -mx-1 mb-4">
																						{!notification.attributes.is_read && (
																							<div className="px-1">
																								<span className="inline-block w-[6px] h-[6px] bg-[#F9C933] rounded-[50%]"></span>
																							</div>
																						)}

																						<div className="px-1 w-[80%]">
																							<h5 className="text-[14px] text-[#393939] font-[600]">{notification.attributes.title}</h5>
																							<span className="text-[10px] text-[#393939] block leading-[1.1] mb-1">{notification.attributes.descp}</span>
																							<p className="text-[10px] text-[#818181]">{time}</p>
																						</div>
																						<div className="px-1">
																							{notification.attributes.image_link != '' ? (
																								<div className="bg-primary w-[44px] h-[44px] rounded-[6px] flex justify-center items-center">
																									<i className="fa-regular fa-bell text-white"></i>
																								</div>
																							) : (
																								<Image loader={myLoader} src={notification.attributes.image_link} width={44} height={44} alt="" className="rounded-[6px]" />
																							)}
																						</div>
																					</a>
																				</li>
																			);
																		})}
																	</ul>
																) : (
																	<i className="fas fa-spinner fa-spin text-black"></i>
																)}
															</div>
														) : (
															<div className="">
																<i className="fas fa-bullhorn text-4xl mb-4 text-[#c3d1dd]"></i>
																<h4 className="font-semibold mb-2">You've got no notifications</h4>
																<p className="text-xs text-[#323c45] mb-4">You can message anyone by hitting 'Send a Message' on their profile.</p>
															</div>
														)}
													</li>
													<li className={openTab === 4 ? 'block text-center py-6' : 'hidden'} id="link4">
														<i className="fas fa-user text-4xl mb-4 text-[#c3d1dd]"></i>
														<h4 className="font-semibold mb-2">You haven't added any friends.</h4>
														<p className="text-xs text-[#323c45] mb-4">Go make friends by hitting 'Add Friend' on their profile.</p>
													</li>
												</ul>
												<div className="fixed inset-0 z-[-1]" onClick={() => setShowProfileMobileDropdown(false)}></div>
											</>
										) : null}
									</div>
								) : (
									<button className="bg-theme inline-block lg:hidden rounded-[8px] px-5 py-1.5 font-medium text-black sm:mr-4 mr-2" onClick={handleLoginWindow}>
										Login
									</button>
								)}
								<button
									onClick={(e) => {
										setShowMe(!showMe);
									}}
									ref={button}
									className="flex items-center px-4 py-0 rounded-[8px] text-white border-white">
									<svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<title>Menu</title>
										<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
									</svg>
								</button>
							</div>
							<div
								ref={dropdown}
								className="w-full NavContainer flex-grow lg:flex lg:items-center lg:w-auto lg:justify-end none"
								style={{
									display: showMe ? 'block' : 'none',
								}}>
								<Link href="https://about.gurucool.xyz/">
									<a className="block lg:inline-block text-white lg:px-4 py-4 hover:text-primary" target="_blank">
										<span className="block text-sm uppercase text-theme">&nbsp;</span>
										<span className="font-medium">About</span>
									</a>
								</Link>
								<CourseDropdown />

								<div className="relative cursor-pointer">
									<a
										className="block lg:inline-block text-white lg:px-4 py-4 hover:text-primary"
										onMouseEnter={(e) => {
											setStyle({ display: 'block' });
										}}
										onMouseLeave={(e) => {
											setStyle({ display: 'none' });
										}}>
										<span className="block text-sm uppercase text-theme">Free</span>
										<span className="font-medium">
											Study Material <i className="fas fa-chevron-down"></i>
										</span>
									</a>
									<div
										className="parent lg:absolute bg-white shadow-lg lg:w-64 dropdown-menu z-30 fixed lg:top-full w-full top-0 lg:h-auto h-screen left-0 right-0"
										style={style}
										onMouseEnter={(e) => {
											setStyle({ display: 'block' });
										}}
										onMouseLeave={(e) => {
											setStyle({ display: 'none' });
										}}>
										<div className="bg-white shadow lg:hidden flex justify-between p-4 pb-3 mb-3">
											<a className="text-black">
												<span className="text-[17px]">Study Material</span>
											</a>
											<a
												className="text-black"
												onClick={(e) => {
													setStyle({ display: 'none' });
												}}>
												<i className="text-lg  mt-[-3px] fas fa-xmark"></i>
											</a>
										</div>
										{!isLoading &&
											data.map((valueOne: any, indexOne: number) => {
												return (
													<div className="relative" key={indexOne}>
														<a
															className="flex px-4 py-1 min-h-[55px] text-black justify-between items-center hover:bg-primary text-[15px]"
															onMouseEnter={(e) => {
																hideShowChild(indexOne, 'block');
															}}
															onMouseLeave={(e) => {
																hideShowChild(indexOne, 'none');
															}}>
															<span className="text-[16px] lg:text-[15px]">
																{valueOne.attributes.subcat_id.data.length == 0 && <span className="bg-[#ff0000] inline-block text-[8px] text-white p-1 rounded-[4px] uppercase font-semibold leading-[7px] align-middle">Soon</span>}
																<span className="block">{valueOne.attributes.name}</span>
															</span>

															<i className="fas fa-chevron-right lg:text-[14px]"></i>
														</a>
														<div
															className={'child' + indexOne + ' ' + 'dropdown-submenu lg:absolute lg:w-64 w-full z-30 fixed lg:h-auto h-screen lg:left-auto left-0 bg-white shadow-lg'}
															onMouseEnter={(e) => {
																hideShowChild(indexOne, 'block');
															}}
															onMouseLeave={(e) => {
																hideShowChild(indexOne, 'none');
															}}
															style={displayHidden}>
															<div className="bg-white shadow lg:hidden flex justify-between p-4 pb-3 mb-3">
																<a
																	className="text-black"
																	onClick={(e) => {
																		hideShowChild(indexOne, 'none');
																	}}>
																	<i className="fas fa-arrow-left"></i>
																	<span className="text-[17px] ml-3">{valueOne.attributes.name}</span>
																</a>
																<a
																	onClick={(e) => {
																		setStyle({ display: 'none' });
																	}}
																	className="text-black">
																	<i className="text-lg  mt-[-3px] fas fa-xmark"></i>
																</a>
															</div>
															{!isLoading &&
																valueOne.attributes.subcat_id.data.map((valueTwo: any, indexTwo: number) => {
																	return (
																		<div className="relative" key={indexTwo}>
																			<a
																				className="flex px-4 py-3 text-black justify-between items-center hover:bg-primary text-[15px]"
																				onMouseEnter={(e) => {
																					hideShowSubChild(indexOne, indexTwo, 'block');
																				}}
																				onMouseLeave={(e) => {
																					hideShowSubChild(indexOne, indexTwo, 'none');
																				}}>
																				<span className="text-[16px] lg:text-[15px]">
																					{valueTwo.attributes.name}
																					{valueTwo.attributes.material_id.data.length == 0 && <span className="bg-[#ff0000] inline-block text-[8px] text-white p-1 rounded-[4px] uppercase font-semibold leading-[7px] align-middle">Soon</span>}
																				</span>
																				<i className="fas fa-chevron-right lg:text-[14px]"></i>
																			</a>

																			<div
																				className={'subchild' + indexOne + indexTwo + ' ' + 'dropdown-submenu lg:absolute lg:w-64 w-full z-30 fixed lg:h-auto h-screen lg:left-auto left-0 bg-white shadow-lg'}
																				style={displayHidden}
																				onMouseEnter={(e) => {
																					hideShowSubChild(indexOne, indexTwo, 'block');
																				}}
																				onMouseLeave={(e) => {
																					hideShowSubChild(indexOne, indexTwo, 'none');
																				}}>
																				<div className="bg-white shadow lg:hidden flex justify-between p-4 pb-3 mb-3">
																					<a
																						className="text-black"
																						onClick={(e) => {
																							hideShowSubChild(indexOne, indexTwo, 'none');
																						}}>
																						<i className="fas fa-arrow-left"></i>
																						<span className="text-[17px] ml-3">{valueTwo.attributes.name}</span>
																					</a>
																					<a
																						onClick={(e) => {
																							setStyle({ display: 'none' });
																						}}
																						className="text-black">
																						<i className="text-lg  mt-[-3px] fas fa-xmark"></i>
																					</a>
																				</div>
																				{!isLoading &&
																					valueTwo.attributes.material_id.data.map((valueThree: any, indexThree: number) => {
																						return (
																							<Link key={indexThree} href={valueThree.attributes.link}>
																								<a className="text-[16px] lg:text-[15px] flex px-4 py-3 text-black hover:bg-primary" target="_blank">
																									{valueThree.attributes.name}
																								</a>
																							</Link>
																						);
																					})}
																			</div>
																		</div>
																	);
																})}
														</div>
													</div>
												);
											})}
									</div>
								</div>
								<Link href="https://asked.gurucool.xyz/">
									<a target="_blank" className="block lg:inline-block text-white lg:px-4 py-4 hover:text-primary">
										<span className="block text-sm uppercase text-theme">Hot</span>
										<span className="font-medium"> Solved Questions</span>
									</a>
								</Link>
								<Link href="/doubt-space">
									<a onClick={() => setShowMe(false)} className="block lg:inline-block text-white mt-[-15px] sm:mt-0 mb-4 sm:mb-0 lg:pl-4 lg:pr-6 py-4 hover:text-primary">
										<span className="block text-sm uppercase text-theme">&nbsp;</span>
										<span className="font-medium">Doubt?</span>
									</a>
								</Link>
								{isLoggedIn ? (
									<div className="lg:inline-block hidden relative">
										<a
											ref={buttonProfile}
											className="flex items-center cursor-pointer py-3"
											onClick={(e) => {
												setShowProfileDropdown(!showProfileDropdown);
											}}>
											<Image loader={myLoader} src={userData && userData.attributes.avatar.data != null ? userData.attributes.avatar.data.attributes.url : '/man.svg'} alt="" width={56} height={56} className="rounded-full" />
											<span className="text-white ml-2 hidden">{userData && userData.attributes.first_name ? `${userData.attributes.first_name} ${userData.attributes.last_name}` : user.username}</span>
										</a>
										{showProfileDropdown ? (
											<>
												<ul ref={dropdownProfile} className="dropdown-menu py-4 absolute bg-white top-full rounded-md shadow-lg md:w-[330px] w-[90%] min-h-[454px]">
													<li className="flex justify-between py-3 px-4 border-b border-b-gray-300 mb-2">
														<a
															onClick={(e) => {
																e.preventDefault();
																setOpenTab(1);
															}}
															data-toggle="tab"
															href="#link1"
															role="tablist"
															className={openTab === 1 ? 'flex justify-center items-center text-lg bg-[#FFF6D9] w-9 h-9 rounded-full' : 'flex justify-center items-center text-lg w-9 h-9 rounded-full'}>
															<Image src={userIcon} width={20} height={20} alt="" />
														</a>
														<a
															onClick={(e) => {
																e.preventDefault();
																setOpenTab(2);
															}}
															data-toggle="tab"
															href="#link2"
															role="tablist"
															className={openTab === 2 ? 'flex justify-center items-center text-lg bg-[#FFF6D9] w-9 h-9 rounded-full' : 'flex justify-center items-center text-lg w-9 h-9 rounded-full'}>
															<Image src={message} width={20} height={20} alt="" />
														</a>
														<a
															onClick={(e) => {
																e.preventDefault();
																setOpenTab(3);
																GetNotifications();
															}}
															data-toggle="tab"
															href="#link3"
															role="tablist"
															className={openTab === 3 ? 'flex justify-center items-center text-lg bg-[#FFF6D9] w-9 h-9 rounded-full relative' : 'flex justify-center  relative items-center text-lg w-9 h-9 rounded-full'}>
															<Image src={notification} width={20} height={20} alt="" />
															{notificationCount > 0 && <span className="absolute bg-[red] w-[20px] h-[20px] rounded-full right-[-5px] top-[-5px] text-[10px] text-white z-[10] flex justify-center items-center">{notificationCount}</span>}
														</a>
														<a
															onClick={(e) => {
																e.preventDefault();
																setOpenTab(4);
															}}
															data-toggle="tab"
															href="#link4"
															role="tablist"
															className={openTab === 4 ? 'flex justify-center items-center text-lg bg-[#FFF6D9] w-9 h-9 rounded-full' : 'flex justify-center items-center text-lg w-9 h-9 rounded-full'}>
															<Image src={users} width={20} height={20} alt="" />
														</a>
													</li>
													<li className={openTab === 1 ? 'block' : 'hidden'} id="link1">
														<ul>
															<li className="flex justify-between items-center pt-3 px-4">
																<a className="flex items-center">
																	<Image loader={myLoader} src={userData && userData.attributes.avatar.data != null ? userData.attributes.avatar.data.attributes.url : '/man.svg'} alt="" width={50} height={50} className="rounded-full" />
																	<span className="ml-2 text-lg font-medium">{userData && userData.attributes && userData.attributes.first_name ? `${userData.attributes.first_name} ${userData.attributes.last_name}` : user.username}</span>
																</a>
																{/* <span className="moon-sun mx-3">
																	<input type="checkbox" tabIndex={32} name="ct0" id="ct0" />
																	<label
																		htmlFor="ct0"
																		onClick={(e) => {
																			ToggleClass();
																		}}
																	>
																		<span></span>
																		<i className="cloud"></i>
																		<i className="stars"></i>
																	</label>
																</span> */}
															</li>
															<li className="py-3 px-4 border-b border-b-gray-300 mb-4">
																<span className="inline-block bg-gray-100 px-4 py-1 rounded-full text-sm">
																	<strong className="text-black">{balance}</strong> Coins
																</span>
																<span className="inline-block bg-gray-100 px-4 py-1 rounded-full text-sm ml-3">
																	<i className="far fa-crown text-theme"></i> <strong className="text-black">0</strong>
																</span>
															</li>
															{/* <li
																className="mb-3"
																onClick={(e) => {
																	setShowProfileDropdown(!showProfileDropdown);
																}}>
																<Link href="/mylearning">
																	<a className="flex px-4 py-2 justify-between items-center">
																		<span>
																			<i className="far fa-book-open"></i> <span className="ml-2">My Learning</span>
																		</span>
																		<i className="fas fa-chevron-right"></i>
																	</a>
																</Link>
															</li> */}
															{user.is_admin && (
																<li
																	className="mb-3"
																	onClick={(e) => {
																		setShowProfileDropdown(!showProfileDropdown);
																	}}>
																	<Link href="https://admin.gurucool.xyz/">
																		<a className="flex px-4 py-2 justify-between items-center">
																			<span>
																				<i className="fa-regular fa-toggle-off"></i> <span className="ml-2">Control Panel</span>
																			</span>
																			<i className="fas fa-chevron-right"></i>
																		</a>
																	</Link>
																</li>
															)}
															<li
																className="mb-3"
																onClick={(e) => {
																	setShowProfileDropdown(!showProfileDropdown);
																}}>
																<Link href="/profile">
																	<a className="flex px-4 py-2 justify-between items-center" onClick={setDropdownShow}>
																		<span>
																			<i className="far fa-user"></i> <span className="ml-2">View Profile</span>
																		</span>
																		<i className="fas fa-chevron-right"></i>
																	</a>
																</Link>
															</li>
															{/* <li className="mb-3">
													<Link href="/achievements">
														<a className="flex px-4 py-2 justify-between items-center" onClick={setDropdownShow}>
															<span>
																<i className="far fa-shield"></i> <span className="ml-2">Achievements</span>
															</span>
															<i className="fas fa-chevron-right"></i>
														</a>
													</Link>
												</li> */}
															<li
																className="mb-3"
																onClick={(e) => {
																	setShowProfileDropdown(!showProfileDropdown);
																}}>
																<Link href="/editprofile">
																	<a className="flex px-4 py-2 justify-between items-center" onClick={setDropdownShow}>
																		<span>
																			<i className="far fa-pen-to-square"></i> <span className="ml-2">Edit Profile</span>
																		</span>
																		<i className="fas fa-chevron-right"></i>
																	</a>
																</Link>
															</li>
															{isCampusDahsboard&&<li className="mb-3">
															   <Link href="http://dashboard.gurucool.xyz/">
																	<a className="flex px-4 py-2 justify-between items-center" onClick={setDropdownShow}>
																		<span className='inline-flex items-center'>
																		<Image src={CampusIcon} alt=''/>  <span className="ml-2">Campus Dashboard</span>
																		</span>
																		<i className="fas fa-chevron-right"></i>
																	</a>
																</Link>
															</li>}
															<li className="mb-3">
																<button className="flex w-full px-4 py-2 justify-between items-center" onClick={handleLogout}>
																	<span>
																		<i className="fas fa-arrow-right-from-bracket"></i> <span className="ml-2">Log Out</span>
																	</span>
																	<i className="fas fa-chevron-right"></i>
																</button>
															</li>
															
														</ul>
													</li>
													<li className={openTab === 2 ? 'block text-center py-6 px-2' : 'hidden'} id="link2">
														<i className="fas fa-messages text-4xl mb-4 text-[#c3d1dd]"></i>
														<h4 className="font-semibold mb-2">No messages. Yet</h4>
														<p className="text-xs text-[#323c45] mb-4">You can message anyone by hitting 'Send a Message' on their profile.</p>
														<a className="text-black bg-[#ebf2f7] rounded-full py-2 px-5 inline-block text-xs uppercase">Go to inbox</a>
													</li>
													<li className={openTab === 3 ? 'block text-center py-1 px-2' : 'hidden'} id="link3">
														{notifications.length > 0 ? (
															<div className="text-left">
																<h4 className="text-sm font-[600] mb-4">Notifications</h4>
																{!notificationLoading ? (
																	<ul className="max-h-[300px] overflow-y-scroll overflow-x-hidden">
																		{notifications.map((notification: any, index: number) => {
																			let time = timeSince(new Date(notification.attributes.createdAt));
																			return (
																				<li key={index}>
																					<a
																						onClick={async () => {
																							if (!notification.attributes.is_read) {
																								await MarkasRead(notification.id);
																							}
																							if (notification.attributes.url) {
																								window.location.href = notification.attributes.url;
																							}
																						}}
																						className="flex -mx-1 mb-4">
																						{!notification.attributes.is_read && (
																							<div className="px-1">
																								<span className="inline-block w-[6px] h-[6px] bg-[#F9C933] rounded-[50%]"></span>
																							</div>
																						)}

																						<div className="px-1 w-[80%]">
																							<h5 className="text-[12px] text-[#000000] font-[600]">{notification.attributes.title}</h5>
																							<span className="text-[10px] text-[#393939] block leading-[1.1] mb-1">{notification.attributes.descp}</span>
																							<p className="text-[10px] text-[#818181]">{time}</p>
																						</div>
																						<div className="px-1">
																							{notification.attributes.image_link != '' ? (
																								<div className="bg-primary w-[44px] h-[44px] rounded-[6px] flex justify-center items-center">
																									<i className="fa-regular fa-bell text-white"></i>
																								</div>
																							) : (
																								<Image loader={myLoader} src={notification.attributes.image_link} width={44} height={44} alt="" className="rounded-[6px]" />
																							)}
																						</div>
																					</a>
																				</li>
																			);
																		})}
																	</ul>
																) : (
																	<i className="fas fa-spinner fa-spin text-black"></i>
																)}
															</div>
														) : (
															<div className="">
																<i className="fas fa-bullhorn text-4xl mb-4 text-[#c3d1dd]"></i>
																<h4 className="font-semibold mb-2">You've got no notifications</h4>
																<p className="text-xs text-[#323c45] mb-4">You can message anyone by hitting 'Send a Message' on their profile.</p>
															</div>
														)}
													</li>
													<li className={openTab === 4 ? 'block text-center py-6 px-2' : 'hidden'} id="link4">
														<i className="fas fa-user text-4xl mb-4 text-[#c3d1dd]"></i>
														<h4 className="font-semibold mb-2">You haven't added any friends.</h4>
														<p className="text-xs text-[#323c45] mb-4">Go make friends by hitting 'Add Friend' on their profile.</p>
													</li>
												</ul>
											</>
										) : null}
									</div>
								) : (
									<button className="bg-theme lg:inline-block hidden rounded xl:px-12 lg:px-[1rem] py-2.5 font-semibold" onClick={handleLoginWindow}>
										Login
									</button>
								)}
							</div>
						</nav>
					</div>
				) : (
					<div className="c-header-loader">
						<div className="c-slidingLoader">
							<div className="c-slidingLoader-inner"></div>
						</div>
					</div>
				)}
			</header>
		</>
	);
};

export default Navbar;
